import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { MdEmail } from "react-icons/md";
import { SiLinkedin,SiTwitter } from "react-icons/si";
import { GrFacebook } from "react-icons/gr";
import Layout from "../components/layout"
import SEO from "../components/seo"

import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from "react-share";

import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import OversightCompare from '../images/OversightCompare.svg'
import Checkmark from '../images/checkmark.svg'
import X from '../images/x.svg'

const BlogDetail = (props) => {
  const data = props.pageContext.data

  const[sharelink,setShareLink]=useState();

  useEffect(()=>{
    let link=`https://oversight.co/${data.slug}`;
    setShareLink(link);
  })
 const renderOptions = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        // target the contentType of the EMBEDDED_ENTRY to display as you need
        if (node.data.target.sys.contentType.sys.id === "blogPost") {
          return (
            <a href={`/${node.data.target.fields.slug}`}>
              {node.data.target.fields.title}
            </a>
          );
        }
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        // target the contentType of the EMBEDDED_ENTRY to display as you need
        if (node.data.target.sys.contentType.sys.id === "codeBlock") {
          return (
            <pre>
              <code>{node.data.target.fields.code}</code>
            </pre>
          );
        }
  
        if (node.data.target.sys.contentType.sys.id === "videoEmbed") {
          return (
            <iframe
              src={node.data.target.fields.embedUrl}
              height="100%"
              width="100%"
              frameBorder="0"
              scrolling="no"
              title={node.data.target.fields.title}
              allowFullScreen={true}
            />
          );
        }

        if (node.data.target.sys.contentType.sys.id === "featureComparison") {
            return (
              <div>
                <div className='tableComparison'>
                    {/* <h5>{node.data.target.fields.title['en-US']}</h5> */}
                {/* <p>{node.data.target.fields.competitorTitle['en-US']}</p> */}
                    <div className="row tableRowHead">
                        <div className="headerRow">
                            <span className='compareTable'>{node.data.target.fields.title['en-US']}</span>
                        </div>
                        <div className='iconRow'>
                            <div className=""><img className='compareIcon' src={OversightCompare} alt="" /></div>
                            <div className=""><img className='compareIcon' src={node.data.target.fields.competitorLogo['en-US'].fields.file['en-US'].url}/></div>
                        </div>
                    </div>
                    {node.data.target.fields.features['en-US'].map(feature=>{
                        return <div className="row tableRow">
                            <div className="col-6">
                            <span className='tableData'>{feature.fields.title['en-US']}</span>
                            </div>
                            {/* <div className="col-2">{JSON.stringify(feature.fields.oversight['en-US'])}</div> */}
                            {/* <div className="col-2">{JSON.stringify(feature.fields.competitor['en-US'])}</div> */}
                            {feature.fields.oversight === 'yes' || 'Yes' ? 
                                (
                                    <img className='compareTableIcon' src={Checkmark} alt="" />
                                ) 
                                : 
                                (
                                    <img className='compareTableIcon' src={X} alt="" />
                                )   
                            }
                            {feature.fields.competitor === 'yes' || 'Yes' ? 
                                (
                                    <img className='compareTableIcon' src={X} alt="" />
                                    ) 
                                    : 
                                    (
                                    <img className='compareTableIcon' src={Checkmark} alt="" />
                                )   
                            }
                        </div>
                    })}  
                    <span className='tableCaption'>{node.data.target.fields.footerText['en-US']}</span>
                </div>
              </div>
            );
          }
        
      },
  
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        // render the EMBEDDED_ASSET as you need
        return (
          <div>
            <img
              src={`https://${node.data.target.fields.file['en-US'].url}`}
              width={"100%"}
              alt={node.data.target.fields.description}
          /></div>
        );
      },
    },
  };
  
  return <Layout pageInfo={{ pageName: data.slug }}>
    <SEO 
      title={data.seoTitle} 
      description={data.seoDescription?.seoDescription} 
      image={data.featuredImage?.file.url}
    />
    <div className='blog-details'>
      <div className='crumb'>
        <Link to='/'>Home</Link>
        <div className="aBreak">/</div>
        <Link to="/blog">Blog</Link>
        <div className="aBreak">/</div>
        <div className='detailTitle'>{data.title}</div>
      </div>
      <div className="share">
        <h5>Share</h5>
        <div className='shareLine'></div>
        <LinkedinShareButton
            url={`https://oversight.co/${data.slug}`}
            summary={'Check out our new blog post!'}
            title={'#Oversight'}
        >

            <SiLinkedin className='img'/>
        </LinkedinShareButton>
        <TwitterShareButton
            url= {`https://oversight.co/${data.slug}`}
            title={'Check out our new blog post!'}
            hashtag={'#Oversight'}
        >
            <SiTwitter className='img' />
        </TwitterShareButton>
        <FacebookShareButton
            url= {`https://oversight.co/${data.slug}`}
            quote={'Check out our new blog post!'}
            hashtag={'#Oversight'}
        >
            <GrFacebook className='img' />
        </FacebookShareButton>
        <EmailShareButton
            url= {`https://oversight.co/${data.slug}`}
            subject={'Check out our new blog post!'}
        >
            <MdEmail className='img'/>
        </EmailShareButton>
      </div>
      <div className="contents">
        <div className="header">
            <h1>{ data.title }</h1>
        </div>
        <div className='authorDiv'>
            <img className='authImg' src={data.byline.graphic.file.url} /> 
            <span className='author'>{data.byline.title}</span>
        </div>
        <div className="blogImg">
            <img className='mainImg' src={data.featuredImage.file.url} alt="" />
        </div>
        <div className="details">
            <div className="detailContent">
                <ul className='tags'>{data.tags.map(data=><li className='taggers'>{data}</li>)}</ul>
                <div className='clock'>
                    <span className='date'>{data.date}</span>
                    <div className="dateLine"></div>
                    <span className='time'>{data.readTime} read</span>
                </div>
                <div className="share-small">
                    <LinkedinShareButton
                      url= {`https://oversight.co/${data.slug}`}
                      summary={'Check out our new blog post!'}
                      title={'#Oversight'}
                    >

                      <SiLinkedin className='img'/>
                    </LinkedinShareButton>
                    <TwitterShareButton
                      url= {`https://oversight.co/${data.slug}`}
                      title={'Check out our new blog post!'}
                      hashtag={'#Oversight'}
                    >
                      <SiTwitter className='img' />
                    </TwitterShareButton>
                    <FacebookShareButton
                      url= {`https://oversight.co/${data.slug}`}
                      quote={'Check out our new blog post!'}
                      hashtag={'#Oversight'}
                    >
                        <GrFacebook className='img' />
                    </FacebookShareButton>
                    <EmailShareButton
                      url= {`https://oversight.co/${data.slug}`}
                      subject={'Check out our new blog post!'}
                    >
                        <MdEmail className='img'/>
                    </EmailShareButton>
                </div>
                <div className="description">
                  <div className='contentText'>{documentToReactComponents(JSON.parse(data.post.post), renderOptions)}</div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </Layout>
}

export default BlogDetail;